<template>
  <div class="height-100 max_box">
    <!-- <div class="play_layer" id="playLayer" @click="startPlay">
      <span class="play_btn"> </span>
    </div> -->
    <!-- <div class="invalid" v-if="isInvalid">页面不可用</div> -->
    <div class="audio-box">
      <!-- 免责声明 页面10需求不要旁白 -->
      <!-- <audio
        src="http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/yinhe/RaisingMeHigher2.mp3"
        loop="loop"
        ref="bgAudio"
      ></audio> -->
      <audio
        @canplay="getDuration(1)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-4d46c48a.mp3"
        ref="audio1"
      ></audio>
      <audio
        @canplay="getDuration(2)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-aa07519f.mp3"
        ref="audio2"
      ></audio>
      <audio
        @canplay="getDuration(3)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-e4f858d3.mp3"
        ref="audio3"
      ></audio>
      <audio
        @canplay="getDuration(4)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-55d1b16d.mp3"
        ref="audio4"
      ></audio>
      <audio
        @canplay="getDuration(5)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-4fde42fb.mp3"
        ref="audio5"
      ></audio>
      <audio
        @canplay="getDuration(6)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-b809fc72.mp3"
        ref="audio6"
      ></audio>
      <audio
        @canplay="getDuration(7)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-d0166b92.mp3"
        ref="audio7"
      ></audio>
      <audio
        @canplay="getDuration(8)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-94ed8c23.mp3"
        ref="audio8"
      ></audio>
      <audio
        @canplay="getDuration(9)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-54ea211e.mp3"
        ref="audio9"
      ></audio>
    </div>

    <!-- pop_box -->
    <div :class="{ pop_box: true, isIframeVisible: isIframeVisible }">
      <i class="pop_close" @click.stop="popClose"></i>
      <div
        class="iframe_layer"
        @click.stop="popClose"
        v-show="isIframeVisible"
      ></div>
      <iframe
        :src="iframeUrl"
        frameborder="0"
        v-show="isIframeVisible"
      ></iframe>
    </div>

    <div class="animate__animated animate__fadeIn duration" id="card1">
      <div class="title">
        <div class="title_name">
          <span class="card1_span1 card1_span">盘</span
          ><span class="card1_span card1_span2">中</span
          ><span class="card1_span3 dot card1_span">•</span
          ><span class="card1_span4 card1_span">热</span
          ><span class="card1_span card1_span5">点</span
          ><span class="card1_span6 dot card1_span">•</span
          ><span class="card1_span7 card1_span">解</span
          ><span class="card1_span8 card1_span">读</span>
        </div>
        <div class="title_time card1_span6 card1_span">
          <b>• • •</b>
          <span style="margin-bottom: 2px">2021.11.25</span><b>• • •</b>
        </div>
        <!-- <div class="btn_start card1_span" id="btnStart" @click="startPlay">
          {{ isFullLoaded ? "开始解读" : "加载中..." }}
        </div> -->
      </div>
      <div class="bg2">
        <img src="../../assets/img/yinhedesk2.png" alt="" />
        <div class="shadow1 shadow"></div>
        <div class="shadow2 shadow"></div>
        <div class="bg1"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        昨日盘面回顾
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div>
          <span>沪指涨0.18%，</span><span>深成指涨0.04%，</span
          ><span>有<i class="color_EF622E">1625</i>股下跌</span>
        </div>
        <div class="section">
          <div class="section_box box_list1">
            <div>沪指</div>
            <div>
              <span>报<i class="color_EF622E" id="number1"></i>点</span
              ><span class="color_EF622E"><i id="number4"></i>%</span>
            </div>
            <img src="../../assets/img/up2.png" alt="" />
          </div>
          <div class="section_box box_list2">
            <div>深成指</div>
            <div>
              <span>报<i class="color_38B163" id="number2">3685.25</i>点</span
              ><span class="color_38B163"><i id="number5"></i>%</span>
            </div>
            <img src="../../assets/img/down2.png" alt="" />
          </div>
          <div class="section_box box_list3">
            <div>创业板指</div>
            <div>
              <span>报<i class="color_EF622E" id="number3">3685.25</i>点</span
              ><span class="color_EF622E"><i id="number6"></i>%</span>
            </div>
            <img src="../../assets/img/up2.png" alt="" />
          </div>
        </div>
        <div class="des_bottom box_list4">
          <div><span>盘面上：</span>钢铁、水泥、旅游等板块跌幅居前</div>
          <div>
            <span>吸金方面：</span
            >北向资金全天净买入36.27亿元，其中，深股通净买额36.94亿元，沪股通净买额-0.67亿元。
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="box card2_box1 shijian">
          <div class="box_title">事件</div>
          <p class="box_content">
            2020年全国人口出生率首次跌破1%创下自1978来新低
          </p>
        </div>
        <div class="box card2_box2">
          <div class="box_title bg_brown">主题</div>
          <p class="box_content">
            低生育趋势成为中国未来经济和社会发展的一大隐忧，辅助生殖市场再次站在了风口上
          </p>
        </div>
        <div class="card2_box3">
          <div class="address">
            <p>更多信息：</p>
            同年结婚登记人数连续7年下降创下新低
          </div>
          <div class="address">
            到2030年，中国的辅助生殖检测市场容量将达到460亿元/年，全球容量将超过1000亿元/年。
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box none">
        <div class="title_card">热点解读</div>
        <p>
          随着“三胎”支持政策在各地落地，高龄生育困难、不孕不育等问题愈加凸显，辅助生殖技术随之受到重视。中国辅助生殖在全球的市场份额占比从2014年的11.27%上升至2020年的18.12%，
          <span class="color_red"
            >未来这一比例将持续提高，市场有望在未来保持高增长态势，尤其辅助生殖龙头企业，未来成长值得期待。</span
          >
        </p>
        <div class="tips_holder">
          <div
            class="tips"
            @click="
              showIframe(
                'https://baike.baidu.com/item/%E4%B8%89%E5%AD%A9%E6%94%BF%E7%AD%96/22807007?fr=aladdin'
              )
            "
          >
            “三胎政策”百科注解
          </div>
        </div>
        <div class="bg_gif"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card">板块指数</div>
        </div>
        <div class="chart1_dec">
          <span>辅助生殖</span>
          <span>MA5 {{ ma5 }}</span>
          <span>MA10 {{ ma10 }}</span>
          <span>MA20 {{ ma20 }}</span>
        </div>
        <div class="d3_chart d3_k" id="d3K"></div>
        <div class="d3_chart_ticks">
          <span>{{ startDate }}</span
          ><span>{{ endDate }}</span>
        </div>
        <div class="d3_chart d3_volume" id="d3Volume"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card">辅助生殖-板块资金</div>
        </div>
        <div class="chart1_dec">
          <span>主力资金（亿）</span>
        </div>
        <div class="chart" id="main_chart"></div>
        <div class="chart1_dec margin-t-10">
          <span>北上资金</span>
        </div>
        <div class="chart" id="bei_chart"></div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title7">相关股票</div>
        </div>
        <div class="stock_title">
          <span>股票代码</span>
          <span>最新价</span>
          <span>涨跌幅</span>
        </div>
        <div class="relative">
          <ul class="stocks_list">
            <li @click="showImage(0)" class="stock_first_li stock_list1">
              <div>
                双鹭药业
                <div>002038</div>
              </div>
              <span>10.42</span>
              <strong class="color_green"><i id="number1_card7"></i>%</strong>
            </li>
            <li class="stock_list2">
              <div>
                奥锐特
                <div>605116</div>
              </div>
              <span>16.17</span>
              <strong class="color_red"><i id="number2_card7"></i>%</strong>
            </li>
            <li class="stock_list3">
              <div>
                悦心健康
                <div>002162</div>
              </div>
              <span>4.99</span>
              <strong class="color_red"><i id="number3_card7"></i>%</strong>
            </li>
            <li class="stock_list4">
              <div>
                太安堂
                <div>002433</div>
              </div>
              <span>7.09</span>
              <strong class="color_green"><i id="number4_card7"></i>%</strong>
            </li>
            <li class="stock_list5">
              <div>
                澳洋健康
                <div>002172</div>
              </div>
              <span>4.07</span>
              <strong class="color_green"><i id="number5_card7"></i>%</strong>
            </li>
          </ul>
          <div class="gp_tips gp_tips_card xiaoshou">
            <img src="../../assets/img/hand_add.png" alt="" />
            <span>点击查看详情</span>
            <div class="power none"></div>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title8">相关基金</div>
        </div>
        <div class="stock_title">
          <span>基金代码</span>
          <span>最新净值</span>
          <span>涨跌幅</span>
        </div>
        <div class="relative">
          <ul class="stocks_list fund_list">
            <li @click="showImage(1)" class="stock_first_li stock_list1">
              <div>
                中银中国LOF
                <div>163801.SZ</div>
              </div>
              <span>3.6352</span>
              <strong class="color_red"><i id="number1_card8"></i>%</strong>
            </li>
            <li class="stock_list2">
              <div>
                中银科技创新一年定开
                <div>009411.OF</div>
              </div>
              <span>3.4794</span>
              <strong class="color_red"><i id="number2_card8"></i>%</strong>
            </li>
            <li class="stock_list3">
              <div>
                中银动态策略
                <div>163805.OF</div>
              </div>
              <span>2.3252</span>
              <strong class="color_green"><i id="number3_card8"></i>%</strong>
            </li>
            <li class="stock_list4">
              <div>
                中银蓝筹精选
                <div>163809.OF</div>
              </div>
              <span>2.3081</span>
              <strong class="color_red"><i id="number4_card8"></i>%</strong>
            </li>
            <!-- <li class="stock_list5">
              <div>生物A<div>150257.SZ</div></div>
              <span>3.8810</span>
              <strong class="color_red"><i id="number5_card8"></i>%</strong>
            </li> -->
          </ul>
          <div class="gp_tips gp_tips_card xiaoshou">
            <img src="../../assets/img/hand_add.png" alt="" />
            <span>点击查看详情</span>
            <div class="power none"></div>
          </div>
        </div>
        <div
          class="ad animate__animated animate__fadeInRight none"
          id="ad1"
          @click="
            jumpUrl(
              'http://www.chinastock.com.cn/newsite/cgs-services/ttl/ttlProduct.html'
            )
          "
        >
          <img src="../../assets/img/yinhead.png" alt="" />
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card9"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        盘中热点解读
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">2021.11.25</div>
      </div>
      <div class="content_box">
        <div class="title_relative">
          <div class="title_card box_title box_title9">相关资讯</div>
        </div>
        <div class="news_list relative">
          <li @click="showNews(0)" class="stock_first_li stock_list1">
            <div class="n_title">
              盘后机会挖掘汇总：各地近期均加快出台鼓励生育政策
              婴童概念股异动拉升
            </div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
          <li class="stock_list2">
            <div class="n_title">
              2020年人口出生率跌破1% 各地持续推进“三孩”政策
              三胎概念有望迎来中长期投资机遇
            </div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
          <li class="stock_list3">
            <div class="n_title">
              生育率跌破警戒线！A股，人口衰退概念股，来了！
            </div>
            <div class="n_info"><span>银河证券</span><b>2021.11.25</b></div>
          </li>
          <div class="gp_tips gp_tips_card xiaoshou">
            <img src="../../assets/img/hand_add.png" alt="" />
            <span>点击查看详情</span>
            <div class="power none"></div>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card10"
    >
      <div class="content_box">
        <div class="box_title">免责声明</div>
        <p>
          所有展示信息仅供参考，不构成任何投资建议。
          银河证券对信息的准确性、完整性或可靠性、
          及时性不作任何保证，并非作为买卖、认购证
          券或其它金融工具的邀请或保证。投资者据此
          操作，风险自担。投资有风险，入市需谨慎。
        </p>
        <p>银河证券提示投资者请密切关注自身风险承受 能力及投资目标。</p>
        <!-- <div class="restart" v-show="reStartState" @click="reStart()">
          再看一遍
        </div> -->
      </div>
      <div class="logo-box">
        <img src="../../assets/img/yinhelogo.png" alt="" />
      </div>
    </div>

    <div
      :class="{
        pop_news_holder: true,
        animate__animated: true,
        animate__fadeInUp: isNewsVisible,
        isNewsVisible: isNewsVisible,
      }"
    >
      <div class="pop_news_layer" @click="closeNews"></div>
      <div class="pop_news">
        <i class="pop_close" @click="closeNews"></i>
        <div class="n_title">
          盘后机会挖掘汇总：各地近期均加快出台鼓励生育政策 婴童概念股异动拉升
        </div>
        <div class="n_info"><span>银河证券</span><span>2021.11.25</span></div>
        <div class="n_content">
          <p>
            智通财经获悉，11月24日，受各地近期均加快出台鼓励生育政策消息影响，A股婴童概念股异动拉升，截至发稿，美邦服饰（002269.SZ）、锦泓集团（603518.SH）、爱慕股份（603511.SH）、新华锦（600735.SH）、沐邦高科（603398.SH）涨停；金发拉比（002762.SZ）、福瑞股份（300049.SZ）、爱婴室（603214.SH）、太平鸟（603877.SH）等股拉升上涨。
          </p>
          <p>
            近日，国家统计局官网公布了《中国统计年鉴2021》，在“人口出生率、死亡率和自然增长率”章节，数据显示，2020年的人口出生率为8.52‰，首次跌破10‰，自然增长率为1.45‰，创1978年以来的历史新低，引发各界热议关注，各地近期均加快出台鼓励生育政策。
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="progess" v-show="progessState">
      <span class="play" @click="stop()" v-show="playState"></span>
      <span class="zanting" @click="play()" v-show="!playState"></span>
      <span class="time_color">{{
        currentTime >= 10 ? "0:" + this.currentTime : "0:0" + this.currentTime
      }}</span>
      <input
        id="range"
        type="range"
        min="1"
        max="10"
        :value="rangeValue"
        autofocus
        step="1"
        @change="changeRange()"
      />
      <span class="time_color">{{ "0:" + totalTime }}</span>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
import {
  sleep,
  encrypt,
  decrypt,
  myPrinto,
  numberRoll,
} from "../../utils/common";
import { drawVolume, drawK } from "@/components/kChartNoBorder.js";
import { ImagePreview } from "vant";
import ApiServe from "@/api/index";
import CanvasKeyFrames from "canvaskeyframes";
import { GetParams2 } from "@/utils/common";
// import VConsole from "vconsole";

// 当在生产环境时，打开调试工具
// if (process.env.NODE_ENV == "production") {
// new VConsole();
// }

export default {
  name: "Template55",
  data() {
    return {
      seccode: "2020882313",
      secname: "",
      dataK: [],
      ma5: "",
      ma10: "",
      ma20: "",
      startDate: "",
      endDate: "",
      isIframeVisible: false,
      iframeUrl: "",
      isNewsVisible: false,
      currentCard: 1,
      isFullLoaded: true, //全部需要预先加载的完成标志
      previewImages: [
        require("../../assets/img/stock_img1.jpg"),
        require("../../assets/img/fund_img1.jpg"),
      ],
      reStartState: false,
      playState: true, //  播放状态还是暂停状态
      // isInvalid: false,
      // progessState: false,
      rangeValue: 1,
      card7Animate: false,
      card8Animate: false,
      card9Animate: false,
      timer: null,
      timer2: null,
      totolTime: 59,
      printObj: null,
      currentTime: 1,
      currentTimer: null,
      totalTime: 56,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      recordtimeAll: null,
      audiosOrderList: [],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 54;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    currentCard(val) {
      if (val != 1) {
        //this.homeAnimate.play()
        this.keyFrames1 && this.keyFrames1.stop();
      }
      if (val != 4) {
        this.keyFrames2 && this.keyFrames2.stop();
      }
      // this.rangeValue = this.currentCard;
      //   let percent = (this.rangeValue / 10) * 100;
      //   // #059cfa
      //   $("#range").css(
      //     "background",
      //     "linear-gradient(to right, #035cd5, white " + percent + "%, white"
      //   );
    },
  },

  async mounted() {
    document.title = "银河热点资讯";
    //有效期问题
    console.log(encrypt("2021.11.16"));
    const valid = this.$route.query.v;
    // if (!valid) {
    //   this.isInvalid = true;
    //   return;
    // }
    // const decryptValid = decrypt(valid);
    // if (decryptValid == "") {
    //   this.isInvalid = true;
    //   return;
    // }
    // console.log('decryptValid',decryptValid);
    // const diff = this.$moment(decryptValid).diff(this.$moment(), "day");
    // // console.log('diff',diff);
    // if (diff < 0) {
    //   this.isInvalid = true;
    //   return;
    // }
    this.ajaxKByDate();
    this.ajaxSecname();
    this.homeAniamete();
    this.dramCanvasFrames();
    await sleep(4000);
    if (this.ip && this.port) {
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
          this.startPlay();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    } else {
      // alert("ip，port没获取到");
      this.startPlay();
    }
    // this.playCard3('aaa')
  },
  methods: {
    //加载完毕后才可以播放
    getDuration(count) {
      // if (count >= 6) {
      this.isFullLoaded = true;
      // }
    },
    // 当前时间计时器
    getcurrentTime() {
      this.currentTimer = setInterval(() => {
        if (
          this.currentCard < 10 &&
          this.currentTime < this.totalTime &&
          !this.$refs["bgAudio"].paused
        ) {
          this.currentTime++;
        }
        if (this.currentCard == 10 && this.currentTime < this.totalTime) {
          this.currentTime++;
        }
        if (this.currentTime == this.totalTime) {
          this.playState = false;
        }
      }, 1000);
    },
    // 首页的动画
    async homeAniamete() {
      $(".card1_span1").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span2").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span3").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span4").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span5").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span6").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span7").animate({ opacity: 1 }, 1000);
      await sleep(200);
      $(".card1_span8").animate({ opacity: 1 }, 1000);
      await sleep(400);
      $(".btn_start").animate({ opacity: 1 }, 1000);
    },
    dramCanvasFrames() {
      var count = 0;
      var imgArr = [];
      const _this = this;
      for (var i = 1; i <= 100; i++) {
        (function (i) {
          var img = new Image();
          img.onload = function () {
            img.onload = null;
            count++;
            // 有可能图片加载有快有满慢，所以用角标存
            imgArr[i - 1] = img;
            if (count == 100) {
              _this.imgArr = imgArr;
              _this.initKeyFrames(imgArr);
            }
          };
          img.onerror = function () {};
          img.src = `img/9 (${i}).png`;
        })(i);
      }
    },
    initKeyFrames(imgArr) {
      this.keyFrames1 = new CanvasKeyFrames(
        document.querySelector(".bg1"),
        "array",
        imgArr,
        // { fps: 24, width: 144, height: 256, imgWidth: 144, imgHeight: 256 }
        { fps: 24, width: 250, height: 450, imgWidth: 250, imgHeight: 450 }
      );
      this.keyFrames2 = new CanvasKeyFrames(
        document.querySelector(".bg_gif"),
        "array",
        imgArr,
        // { fps: 24, width: 144, height: 256, imgWidth: 144, imgHeight: 256 }
        { fps: 24, width: 250, height: 450, imgWidth: 250, imgHeight: 450 }
      );
      this.keyFrames1.repeatplay(0, 99);
      //this.keyFrames1.play()
    },
    // 平移动画
    transformAction(dom, isLoop, offsetY) {
      this.homeAnimate = this.$anime({
        targets: dom,
        translateY: offsetY,
        loop: isLoop,
        direction: "alternate",
        easing: "easeInOutSine",
      });
    },
    // 卡片打字机
    printCard(str, newStr, speed = 60) {
      this.printObj = new myPrinto(speed);
      this.printObj.init(str);
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.timer = setInterval(() => {
        this[newStr] = this.printObj.newStr;
      }, 200);
    },
    // 页面card2的数值滚动
    async card2Opacity() {
      numberRoll("number1", 3446.98);
      numberRoll("number2", 14350.65);
      numberRoll("number3", 3224.15);
      numberRoll("number4", 2);
      numberRoll("number5", 2.33);
      numberRoll("number6", 2.34);
    },
    // 页面card3动画
    async card3Animate() {
      $("#card3 .card2_box1").animate({ top: 16 }, 1000);
      await sleep(200);
      $("#card3 .card2_box1").css("opacity", 1);
      await sleep(800);
      $("#card3 .card2_box2").animate({ top: "16.2rem" }, 1000);
      await sleep(200);
      $("#card3 .card2_box2").css("opacity", 1);
      await sleep(800);
      $("#card3 .card2_box3").animate({ top: "33rem" }, 1000);
      await sleep(200);
      $("#card3 .card2_box3").css("opacity", 1);
    },
    // 重置card3动画
    card3AnimateInit() {
      $("#card3 .card2_box1").css("opacity", 0);
      $("#card3 .card2_box1").css("top", 40);
      $("#card3 .card2_box2").css("opacity", 0);
      $("#card3 .card2_box2").css("top", "24rem");
      $("#card3 .card2_box3").css("opacity", 0);
      $("#card3 .card2_box3").css("top", "40rem");
    },
    //页面card7,card8的淡出动画
    async cardOpacity(dom) {
      $(`${dom} .stock_list1`).animate({ opacity: 1 }, 800);
      await sleep(400);
      dom == "#card7" && numberRoll("number1_card7", 10.03);
      dom == "#card8" && numberRoll("number1_card8", 17.96);
      $(`${dom} .stock_list2`).animate({ opacity: 1 }, 800);
      await sleep(400);
      dom == "#card7" && numberRoll("number2_card7", 10.0);
      dom == "#card8" && numberRoll("number2_card8", 17.45);
      $(`${dom} .stock_list3`).animate({ opacity: 1 }, 800);
      await sleep(400);
      dom == "#card7" && numberRoll("number3_card7", 9.91);
      dom == "#card8" && numberRoll("number3_card8", 17.26);
      $(`${dom} .stock_list4`).animate({ opacity: 1 }, 800);
      await sleep(400);
      dom == "#card7" && numberRoll("number4_card7", 5.82);
      dom == "#card8" && numberRoll("number4_card8", 17.12);
      $(`${dom} .stock_list5`).animate({ opacity: 1 }, 800);
      await sleep(400);
      dom == "#card7" && numberRoll("number5_card7", 3.83);
      dom == "#card8" && numberRoll("number5_card8", 17.07);
    },
    // 页面card9的淡出动画
    async card9Opacity() {
      $(`#card9 .stock_list1`).animate({ opacity: 1 }, 1000);
      await sleep(500);
      $(`#card9 .stock_list2`).animate({ opacity: 1 }, 1000);
      await sleep(500);
      $(`#card9 .stock_list3`).animate({ opacity: 1 }, 1000);
    },
    // 页面7,8,9的引导动画
    async cardGuideAnimaete(par, cardAniamate) {
      if (!cardAniamate) {
        $(`${par} .gp_tips_card`).animate({ top: "-26px" }, 1500);
        await sleep(1500);
        $(`${par} .stock_first_li`).addClass("active");
        await sleep(400);
        $(`${par} .power`).removeClass("none");
      }
    },
    // 页面6,7,8的引导动画 初始化
    cardGuideInit(par) {
      $(`${par} .gp_tips_card`).css("top", "-80px");
      $(`${par} .stock_first_li`).removeClass("active");
      $(`${par} .power`).addClass("none");
    },
    jumpUrl(url) {
      window.open(url);
    },
    stopAudio() {
      this.$refs["audio" + this.currentCard].pause();
      this.$refs.bgAudio.pause();
      this.printObj && this.printObj.pause();
    },
    playAudio() {
      this.$refs["audio" + this.currentCard].play();
      // this.$refs.bgAudio.play();
      this.printObj && this.printObj.play();
    },
    async showImage(imageIndex) {
      const _this = this;
      this.stopAudio();
      await sleep(400);
      ImagePreview({
        images: [_this.previewImages[imageIndex]],
        showIndex: false,
        onClose() {
          if (imageIndex == 0) {
            $("#card7 .gp_tips_card").addClass("none");
            $("#card7 .stock_first_li").removeClass("active");
            $("#card7 .power").addClass("none");
            this.card7Animate = true;
          }
          if (imageIndex == 1) {
            $("#card8 .gp_tips_card").addClass("none");
            $("#card8 .stock_first_li").removeClass("active");
            $("#card8 .power").addClass("none");
            this.card8Animate = true;
          }
          // _this.$refs["audio" + _this.currentCard].play();
          this.playAudio();
        },
      });
    },
    showNews(newsIndex) {
      const _this = this;
      this.stopAudio();
      this.isNewsVisible = true;
    },
    closeNews() {
      this.isNewsVisible = false;
      this.playAudio();
      this.playState = true;
      $("#card9 .gp_tips_card").addClass("none");
      $("#card9 .stock_first_li").removeClass("active");
      $("#card9 .power").addClass("none");
      this.card9Animate = true;
    },
    showIframe(url) {
      this.isIframeVisible = true;
      this.iframeUrl = url;
      this.stopAudio();
    },
    popClose() {
      this.isIframeVisible = false;
      this.iframeUrl = "";
      this.playAudio();
      this.playState = true;
    },
    //根据begin和end date获取日k
    async ajaxKByDate() {
      const urlParams1 = encodeURIComponent(
        "https://comm.wedengta.com/?s=kline&f=getKLineByDate&req=KLineDateReq&rsp=KLineDateRsp"
      );
      const params = {
        sDtSecCode: this.seccode,
        eKLineType: 4,
        sEndDate: this.$moment().format("YYYYMMDD"),
        iWantnum: 80,
      };
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      const data = await this.$axios.get(newUrl);
      const _data = JSON.parse(data.content).vKLineDesc.reverse();
      // console.log("_data", _data);
      this.dataK = _data;
    },
    async ajaxSecname() {
      const urlParams1 = encodeURIComponent(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp"
      );
      const params = {
        vDtSecCode: [this.seccode],
      };
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      const data = await this.$axios.get(newUrl);
      const _data = JSON.parse(data.content).vSecSimpleQuote;
      this.secname = _data[0].sSecName;
    },
    renderKLine(fullKData) {
      //console.log("fullKData", fullKData);
      // const data = fullKData.slice(frameIndex, frameIndex + 50);
      //用50条数据，算出30条准确的均线数值
      const ave5 = this.getAverageValue(5, fullKData).slice(20, 80);
      const ave10 = this.getAverageValue(10, fullKData).slice(20, 80);
      const ave20 = this.getAverageValue(20, fullKData).slice(20, 80);
      // console.log("ave5", ave5);

      this.ma5 = ave5[ave5.length - 1].toFixed(2);
      this.ma10 = ave10[ave10.length - 1].toFixed(2);
      this.ma20 = ave20[ave20.length - 1].toFixed(2);

      this.startDate = fullKData[19].lYmd;
      this.endDate = fullKData[79].lYmd;

      //30条里加入均线数据
      const dataCombined = this.combineKAve(
        fullKData.slice(20, 80),
        ave5,
        ave10,
        ave20
      );
      //console.log("dataCombined", dataCombined);

      //画k线和量
      drawK({
        id: "d3K",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.66,
      });
      drawVolume({
        id: "d3Volume",
        data: dataCombined,
        width: $("#d3K").width(),
        ratio: 0.25,
      });
    },
    //获取均线
    getAverageValue: function (num, data) {
      let averageValue = [];
      let totalSum = 0;
      if (data.length <= num) {
        for (let i = 0; i < data.length; i++) {
          totalSum += data[i].fClose;
          averageValue[i] = totalSum / (i + 1);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i < num) {
            totalSum += data[i].fClose;
            averageValue[i] = totalSum / (i + 1);
          } else {
            totalSum = 0;
            for (let j = i; j > i - num && j > 0; j--) {
              if (i - j < num) {
                totalSum += data[j].fClose;
              }
            }
            averageValue[i] = totalSum / num;
          }
        }
      }
      return averageValue;
    },
    //合并均线数据到k线数据
    combineKAve: function (data, ave5, ave10, ave20) {
      var _data = data;
      for (var i = 0; i < _data.length; i++) {
        _data[i].ave5 = ave5[i];
        _data[i].ave10 = ave10[i];
        _data[i].ave20 = ave20[i];
      }
      return _data;
    },
    moniteAudioEnd(num) {
      return new Promise(async (resolve, reject) => {
        // const moniteHandler = setInterval(async () => {
        //   if (this.currentCard < 10) {
        //     if (
        //       this.$refs["audio" + this.currentCard].currentTime >=
        //         this.$refs["audio" + this.currentCard].duration - 0.3 &&
        //       num == this.currentCard
        //     ) {
        //       await sleep(400);
        //       clearInterval(moniteHandler);
        //       resolve();
        //     }
        //   }
        // }, 100);
        const timeLength = this.$refs["audio" + this.currentCard].duration;
        console.log(this.$refs["audio" + this.currentCard].duration);

        await sleep(timeLength * 1000);
        resolve();
      });
    },
    // 主力资金
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("main_chart"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "0",
          top: "30px",
          bottom: "30px",
          right: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "category",
            data: ["10-23", "10-26", "10-30", "11-3", "11-6", "11-9", "11-11"],
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: "#9A9A9A",
              },
            },
            axisLabel: {
              color: "#9A9A9A",
              fontSize: 20,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        series: [
          {
            type: "bar",
            data: [0.2, 0.1, 0.05, 0.15, 0.1, 0.2, 0.4],
            barWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#F54E30",
                    fontSize: 20,
                  },
                },
                barBorderRadius: [6, 6, 0, 0],
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#EA7C3E" }, //柱图渐变色
                  { offset: 1, color: "#F54E30" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      };
      myChart.clear();
      myChart.setOption(option, true);
    },
    // 北上资金
    loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("bei_chart"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "0",
          top: "30px",
          bottom: "28px",
          right: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "category",
            data: ["10-23", "10-26", "10-30", "11-3", "11-6", "11-9", "11-11"],
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: "#9A9A9A",
              },
            },
            axisLabel: {
              color: "#9A9A9A",
              fontSize: 20,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        series: [
          {
            type: "bar",
            data: [0.1, 0.2, 0.3, 0.1, 0.5, 0.4, 0.2],
            barWidth: 30,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#F54E30",
                    fontSize: 20,
                  },
                },
                barBorderRadius: [6, 6, 0, 0],
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#EA7C3E" }, //柱图渐变色
                  { offset: 1, color: "#F54E30" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      };
      myChart.clear();
      myChart.setOption(option, true);
    },
    playCard1(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 1;
        // this.$refs.audio1.currentTime = 0;
        // this.$refs.audio1.play();
        this.playState = true;
        $("#card1").removeClass("none");
        $("#btnStart").addClass("none");
        await sleep(2000);
        if (isDebugStop) {
          return;
        }
        $("#card1").addClass("none");
        if (1 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard2(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 2;
        // this.$refs.audio2.currentTime = 0;
        // this.$refs.audio2.play();
        this.playState = true;
        $("#card2").removeClass("none");
        this.card2Opacity();
        await sleep(9400);
        if (isDebugStop) {
          return;
        }
        $("#card2").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card2").addClass("none");

        if (2 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard3(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 3;
        this.card3AnimateInit();
        // this.$refs.audio3.currentTime = 0;
        // this.$refs.audio3.play();
        this.playState = true;
        $("#card3").removeClass("none");
        this.card3Animate();
        await sleep(12400);
        if (isDebugStop) {
          return;
        }
        $("#card3").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card3").addClass("none");

        if (3 == this.currentCard) {
          clearInterval(this.timer);
          this.timer = null;
          resolve();
        }
      });
    },
    playCard4(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 4;
        $("#card4 .content_box").css("display", "none");
        $("#card4 .tips").css("opacity", 0);
        // this.$refs.audio4.currentremoveClassTime = 0;
        // this.$refs.audio4.play();
        this.playState = true;
        $("#card4").removeClass("none");
        $("#card4 .content_box").slideDown(2000);
        setTimeout(() => {
          $("#card4 .tips").animate({ opacity: 1 });
          // this.keyFrames2.play()
          this.keyFrames2.repeatplay(0, 99);
        }, 2500);
        await sleep(7400);
        if (isDebugStop) {
          return;
        }
        await $("#card4").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card4").addClass("none");
        if (4 == this.currentCard) {
          clearInterval(this.timer);
          this.timer = null;
          clearInterval(this.timer2);
          this.timer2 = null;
          resolve();
        }
      });
    },
    playCard5(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 5;
        this.$refs.audio5.currentTime = 0;
        // this.$refs.audio5.play();
        this.playState = true;
        $("#card5").removeClass("none");
        this.renderKLine(this.dataK);
        await sleep(7000);
        if (isDebugStop) {
          return;
        }
        $("#card5").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card5").addClass("none");
        if (5 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard6(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 6;
        this.$refs.audio6.currentTime = 0;
        // this.$refs.audio6.play();
        this.playState = true;
        $("#card6").removeClass("none");
        this.loadChart1();
        this.loadChart2();
        await sleep(6000);
        if (isDebugStop) {
          return;
        }
        $("#card6").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card6").addClass("none");
        if (6 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard7(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 7;
        this.cardGuideInit("#card7");
        $("#card7 .stocks_list li").css("opacity", 0);
        this.$refs.audio7.currentTime = 0;
        // this.$refs.audio7.play();
        this.playState = true;
        $(".box_title7").css("bottom", 0);
        $("#card7").removeClass("none");
        $(".box_title7").animate({ bottom: 10 });
        this.cardOpacity("#card7");
        this.cardGuideAnimaete("#card7", this.card7Animate);
        await sleep(5000);
        if (isDebugStop) {
          return;
        }
        $("#card7").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card7").addClass("none");
        if (7 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard8(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 8;
        this.cardGuideInit("#card8");
        $("#card8 .stocks_list li").css("opacity", 0);
        this.$refs.audio8.currentTime = 0;
        // this.$refs.audio8.play();
        this.playState = true;
        $(".box_title8").css("bottom", 0);
        $("#card8").removeClass("none");
        $(".box_title8").animate({ bottom: 10 });
        this.cardOpacity("#card8");
        this.cardGuideAnimaete("#card8", this.card8Animate);
        setTimeout(() => {
          $("#ad1").removeClass("none");
        }, 1000);
        await sleep(5000);
        if (isDebugStop) {
          return;
        }
        $("#card8").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card8").addClass("none");
        if (8 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard9(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 9;
        this.cardGuideInit("#card9");
        $("#card9 .news_list li").css("opacity", 0);
        this.$refs.audio9.currentTime = 0;
        // this.$refs.audio9.play();
        this.playState = true;
        $(".box_title9").css("bottom", 0);
        $("#card9").removeClass("none");
        $(".box_title9").animate({ bottom: 10 });
        this.card9Opacity();
        this.cardGuideAnimaete("#card9", this.card9Animate);
        await sleep(6400);
        if (isDebugStop) {
          return;
        }
        $("#card9").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card9").addClass("none");
        if (9 == this.currentCard) {
          resolve();
        }
      });
    },
    playCard10(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 10;
        this.playState = true;
        $("#card10").removeClass("none");
        await sleep(2000);
        if (isDebugStop) {
          return;
        }
        this.reStartState = true;
        if (10 == this.currentCard) {
          resolve();
        }
      });
    },
    // 转场的时候不支持暂停播放
    play() {
      if (this.currentCard < 10) {
        if (
          this.$refs["audio" + this.currentCard].currentTime <
          this.$refs["audio" + this.currentCard].duration - 0.3
        ) {
          this.playAudio();
          this.playState = true;
        }
      }
    },
    stop() {
      if (this.currentCard < 10) {
        if (
          this.$refs["audio" + this.currentCard].currentTime <
          this.$refs["audio" + this.currentCard].duration - 0.3
        ) {
          this.stopAudio();
          this.playState = false;
        }
      }
    },
    async playBgAudio() {
      // this.$refs.bgAudio.play(); //背景音乐播放
      await sleep(500);
      // this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
    },
    endRecord() {
      this.audiosOrderList = [
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-1c229d0b.mp3",
          timeIndex: 1,
          audioLen: 2,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-58df7f01.mp3",
          timeIndex: 4,
          audioLen: 8,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-0aca584b.mp3",
          timeIndex: 12,
          audioLen: 13,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-61427c46.mp3",
          timeIndex: 25,
          audioLen: 8,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-2d931ba0.mp3",
          timeIndex: 34,
          audioLen: 7,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-9ef3c715.mp3",
          timeIndex: 41,
          audioLen: 6,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-ee6bb332.mp3",
          timeIndex: 47,
          audioLen: 5,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-6588d8c1.mp3",
          timeIndex: 52,
          audioLen: 5,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-13b788ce.mp3",
          timeIndex: 57,
          audioLen: 5,
        },
      ];

      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 72,
        videmBgm:
          "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/yinhe/RaisingMeHigher2.mp3",
        audiosOrderList: this.audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    async startPlay() {
      if (!this.isFullLoaded) {
        return;
      }
      // $("#playLayer").addClass("none");
      // this.playBgAudio();
      // this.progessState = true;
      // this.getcurrentTime();
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      await this.playCard9();
      await this.playCard10();
      // 总时间
      if (this.ip && this.port) {
        this.endRecord();
      }
    },
    // async play1() {
    //   // this.$refs.audio1.currentTime = 0;
    //   await this.playCard1();
    //   await this.playCard2();
    //   await this.playCard3();
    //   await this.playCard4();
    //   await this.playCard5();
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play2() {
    //   // this.$refs.audio2.currentTime = 0;
    //   await this.playCard2();
    //   await this.playCard3();
    //   await this.playCard4();
    //   await this.playCard5();
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play3() {
    //   // this.$refs.audio3.currentTime = 0;
    //   await this.playCard3();
    //   await this.playCard4();
    //   await this.playCard5();
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play4() {
    //   // this.$refs.audio4.currentTime = 0;
    //   await this.playCard4();
    //   await this.playCard5();
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play5() {
    //   this.$refs.audio5.currentTime = 0;
    //   await this.playCard5();
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play6() {
    //   this.$refs.audio6.currentTime = 0;
    //   await this.playCard6();
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play7() {
    //   this.$refs.audio7.currentTime = 0;
    //   await this.playCard7();
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play8() {
    //   this.$refs.audio8.currentTime = 0;
    //   await this.playCard8();
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play9() {
    //   this.$refs.audio9.currentTime = 0;
    //   await this.playCard9();
    //   await this.playCard10();
    // },
    // async play10() {
    //   this.$refs.audio10.currentTime = 0;
    //   await this.playCard10();
    // },
    // // 重播
    // async reStart() {
    //   this.clearFadeOut();
    //   this.reStartState = false;
    //   clearInterval(this.currentTimer);
    //   this.currentCard = 1;
    //   this.currentTime = 1;
    //   $("#card10").addClass("none");
    //   $(".stocks_list li").css("opacity", 0);
    //   this.card7Animate = false;
    //   this.card8Animate = false;
    //   this.card9Animate = false;
    //   this.startPlay();
    // },
    // // 清除每个card播放之后的animate__fadeOut
    // clearFadeOut() {
    //   $("#card1").removeClass("animate__fadeOut");
    //   $("#card2").removeClass("animate__fadeOut");
    //   $("#card3").removeClass("animate__fadeOut");
    //   $("#card4").removeClass("animate__fadeOut");
    //   $("#card5").removeClass("animate__fadeOut");
    //   $("#card6").removeClass("animate__fadeOut");
    //   $("#card7").removeClass("animate__fadeOut");
    //   $("#card8").removeClass("animate__fadeOut");
    //   $("#card9").removeClass("animate__fadeOut");
    //   $("#card10").removeClass("animate__fadeOut");
    // },
    // // rang进度条change
    // changeRange() {
    //   this.clearFadeOut();
    //   //console.log(this.currentCard,this.$refs["audio" + this.currentCard])
    //   if (this.currentCard < 10) {
    //     this.stopAudio();
    //   }
    //   let page = "#card" + this.currentCard;
    //   $(page).addClass("none");
    //   const range = document.getElementById("range");
    //   clearInterval(this.currentTimer);
    //   if (range.value == 1) {
    //     this.currentTime = 1;
    //     this.play1();
    //   }
    //   if (range.value == 2) {
    //     this.currentTime = 2;
    //     this.play2();
    //   }
    //   if (range.value == 3) {
    //     this.currentTime = 11;
    //     this.play3();
    //   }
    //   if (range.value == 4) {
    //     this.currentTime = 16;
    //     this.play4();
    //   }
    //   if (range.value == 5) {
    //     this.currentTime = 25;
    //     this.play5();
    //   }
    //   if (range.value == 6) {
    //     this.currentTime = 31;
    //     this.play6();
    //   }
    //   if (range.value == 7) {
    //     this.currentTime = 38;
    //     this.play7();
    //   }
    //   if (range.value == 8) {
    //     this.currentTime = 43;
    //     this.play8();
    //   }
    //   if (range.value == 9) {
    //     this.currentTime = 48;
    //     this.play9();
    //   }
    //   if (range.value == 10) {
    //     this.currentTime = 54;
    //     this.playCard10();
    //   }
    //   this.$refs.bgAudio.play();
    //   this.getcurrentTime();
    // },
  },
  destroyed() {
    clearInterval(this.currentTimer);
  },
};
</script>
<style lang="less" scoped>
@import "./Templateyh.less";
#card1 {
  .bg1 {
    width: 270px;
    height: 450px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -334px;
  }
}
#card4 .content_box .bg_gif {
  width: 250px;
  height: 450px;
  position: absolute;
  left: 0%;
  bottom: -2%;
  img {
    width: 100%;
    height: auto;
  }
}
.shijian {
  top: 34px !important;
}
.gp_tips span {
  font-size: 20px;
}
.gp_tips img {
  width: 24%;
  margin-right: 8%;
}
.power {
  left: 12px;
  bottom: -20px;
  height: 44px;
  width: 44px;
}
#card7 .title_card {
  margin-bottom: 0px;
}
.stocks_list li > strong {
  line-height: 44px;
  width: 24%;
}
.card .content_box {
  padding: 1.2rem;
}
#card6 .title_card {
  padding-top: 0;
}
#card5 .content_box .title_card {
  padding-top: 0;
}
#card8 .title_card {
  margin-bottom: 0px;
}
</style>
